* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  overflow: hidden;
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0; /* Remove default body margin */
  font-family: 'Nunito', sans-serif;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1; /* Set a higher z-index */
}

.container {
  z-index: 2; /* Set a lower z-index */
  background-color: rgba(255, 255, 255, 0);
  padding: 20px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading, section {
  font-family: 'Nunito', sans-serif;
  text-align: center;
  color: teal;
  z-index: 2;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heading h1 {
  font-size: 4rem;
  font-weight: 800;
  margin: 10px;
  color: teal;
  opacity: 0;
  text-shadow: 2px 2px 6px black;
}

.heading h2 {
  font-size: 2rem;
  font-weight: 600;
  margin: 10px;
  color: white;
} 

/* Heading Span Styling */

#junior, #father, #nerd {
  opacity: 0;
  text-shadow: 2px 2px 6px black;
}

#about-section, #skills-section, #work-section, #contact-section {
  opacity: 0;
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

h2 {
  font-size: 2em;
  text-shadow: 2px 2px 6px black;
}

h3{
  font-size: 1em;
  color: teal;
}

section article{
  color: white;
  background-color: #000000b0;
  border-radius: 15px;
  box-shadow: 0 0 6px 6px #000000b0;
  width: 80%;
  max-width: 1200px;
}

.column-center{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row-wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.paragraph{
  margin: 15px;
  font-size: 0.8em;
}

button {
  border: none;
  background-color: #f0f8ff00;
  color: teal;
  font-size: 1.2em;
  font-weight: 900;
  display: block;
  margin-bottom: 15px;
  display: none;
}

button:hover{
  color: white;
}

img{
  width: 30px;
  aspect-ratio: 1 /1;
}

.tech{
  margin: 3px;
  padding: 5px 10px;
  background-color: #00000091;
}

.ind-skill{
  margin: 3px 3px 0 3px;
}

.tech-name{
  font-size: 0.7em;
}

.margin-bottom{
  margin-bottom: 15px;
}

.extra-m-bot{
  margin-bottom: 30px;
}

/* WORK */

.works{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.work-instance{
  display: flex;
  flex-direction: row;
  background-color: #00000091;
  margin: 5px;
}

.work-img-div{
  display: none;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.screenshot{
  width: 100px;
  box-shadow: 0 0 2px 2px white;
}

.work-data{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

.links{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.links a{
  text-decoration: none;
  color: rgb(41, 167, 167);
  margin: 0 10px;
}

.links a:hover{
  color: white;
}

.description p{
  font-size: 0.5em;
}

/* CONTACT */

.img-contact{
  width: 100px;
  margin: 0 20px;
}


/* NAV */
nav{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-around;
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 1.5em;
  padding: 15px;
  z-index: 5;
}

nav article{
  padding: 0 15px;
}

nav article:hover{
  color: teal;
  text-shadow: 2px 2px 6px black;
}

/* Fade-in Fade-out Animations */
.fade-in {
  opacity: 0;
  animation: fadeInAnimation 1.5s ease-out forwards;
}

.fade-out {
  opacity: 0;
  animation: fadeOutAnimation 1.5s ease-out forwards;
}

@keyframes fadeInAnimation {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
}

@media screen and (min-width: 480px){
  .work-img-div{
    display: flex;
  }

  .screenshot{
    aspect-ratio: unset;
  }

  .img-contact{
    width: 150px;
  }
}

@media screen and (min-width: 767px){

  h2 {
    font-size: 4rem;
  }

  .paragraph{
    font-size: 1.5em;
  }

  nav{
    font-size: 2em;
  }

  .tech{
    margin: 5px;
  }

  img{
    width: 50px;
  }

  h3{
    font-size: 1.7em;
  }

  .screenshot{
    width: 150px;
  }

  .description p{
    font-size: 0.9em;
  }

  .img-contact{
    width: 200px;
  }
}